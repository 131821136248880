<template>
  <Modal
    :coords="coords"
    :ui="{ close: { base: 'hidden' },
           root: { color: 'bg-plashka-green shadow-[0_5px_14px_0] ring-1 ring-#f1f1f1 ring-inset shadow-black/8',
                   addition: 'flex flex-col gap-4 max-w-80vw max-h-80vh h-80vh', padding: 'p-6 pt-5' } }"
    @close="emits('close')"
  >
    <button
      class="absolute top-0 right-0 flex top-4.5 right-4.5"
      @click="emits('close')"
    >
      <span class="i-custom:x text-firmennyy text-3"></span>
    </button>
    <h2 class="text-tekstovyy text-5.5 font-700">Отследить курьера</h2>
    <div
      id="mapContainer2gis"
      ref="mapContainer"
      class="flex-1.2 max-lg:fixed max-lg:top-0 max-lg:inset-0 max-lg:-z-1 [&_button]:leading-0"
    ></div>
  </Modal>
</template>

<script setup lang="ts">

import { load } from '@2gis/mapgl';

const { $toast } = useNuxtApp();
const mapContainer = ref(null);
// @ts-ignore
let map;

const initMap = async () => {
  await nextTick(() => {
    setTimeout(() => {
      load().then((mapglAPI) => {
        // @ts-ignore
        map = new mapglAPI.Map(mapContainer.value, {
          center: [Number(props.coords.courier[0]), Number(props.coords.courier[1])],
          zoom: 13,
          key: '65a6d130-891f-4d83-b1fa-edcb5f9eeff8'
        });
        // @ts-ignore

        const marker = new mapglAPI.Marker(map, {
          coordinates: props.coords.courier,
          icon: 'https://cdn-icons-png.flaticon.com/128/7428/7428697.png',
          size: [32, 32]
        });

        const addressMarker = new mapglAPI.Marker(map, {
          coordinates: props.coords.address,
          icon: 'https://cdn-icons-png.flaticon.com/128/7466/7466046.png',
          size: [32, 32]
        });
        // @ts-ignore
        // watch(props.coords.courier, (newCoords) => {
        //   if (marker) {
        //     // @ts-ignore
        //     marker.destroy();
        //   }
        //   // @ts-ignore
        //   marker = new mapglAPI.Marker(map, {
        //     coordinates: newCoords
        //   });
        //   // @ts-ignore
        //   map.setCenter(newCoords);
        // });
      });
    }, 200);
  });
};

onMounted(() => {
  initMap();
});

onBeforeUnmount(() => {
  console.log(document.querySelector('#mapContainer2gis'), 'UNMOUNT');
  // @ts-ignore
  if (map) {
    map.destroy();
  }
});
const props = defineProps<{
      coords: {
        courier: [number, number],
        address: [number, number]
      }
  }>();

onMounted(() => {
  console.log(props.coords);
});

const emits = defineEmits(['close', 'update-list']);
</script>
